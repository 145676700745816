import { _notNil } from '@/littledash';
import { Animal, AnimalV1 } from '@/model/Animal.model.ts';
import { Nullable } from '@/model/Common.model.ts';

export const successDialogue = (
  method: 'post' | 'patch' | 'delete',
  type: string,
  subject = false,
  conjunction = false
): string => {
  const verbs = {
    post: 'created',
    patch: 'edited',
    delete: 'deleted',
  };
  const target = subject && `for ${subject}`;

  return `Successfully ${verbs[method]} ${type} ${target}${conjunction ? '. ' + conjunction : '.'}`;
};

export const altIds: Array<{ key: string; title: string }> = [
  {
    key: 'name',
    title: 'Name',
  },
  {
    key: 'alt_ids.tail',
    title: 'Tail',
  },
  {
    key: 'alt_ids.ear',
    title: 'Ear',
  },
  {
    key: 'alt_ids.tag',
    title: 'Tag',
  },
  {
    key: 'alt_ids.donor',
    title: 'Donor',
  },
];

export const altIdsEmptyObject = {
  tail: '',
  ear: '',
  tag: '',
  donor: '',
};

export const isDead = (animal: Nullable<Animal>) =>
  _notNil(animal?.terminated_at) ? 'Deceased' : (animal?.cage?.name ?? '');

export const isDeadV1 = (animal: Nullable<AnimalV1>) =>
  _notNil(animal?.terminated_at) ? 'Deceased' : (animal?.cage?.name ?? '');
