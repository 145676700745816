import { Dispatch, useEffect, useRef, useState } from 'react';
import { _isEmpty, _isNotEmpty, _notNil } from '@/littledash';
import SpreadSheet from '@/components/UI/SpreadSheet';
// @ts-expect-error HOT table
import type HotTable from '@handsontable/react';
import { ApiService } from '@/support/ApiService';
import { CreateBulkDosingState } from '../CreateDosing.utils';
import { CellChange } from 'handsontable/common';
import { StepFormAction } from '@/components/UI/StepForm/StepForm.model';
import Banner from '@/components/UI/Banner';
import Handsontable from 'handsontable';
import { errorToast, formatNumber } from '@/helpers.tsx';
import Loading from '@/components/Loading';
import { NoDataMessage } from '@/components/NoDataCard';
import { ExceptionHandler } from '@/utils/ExceptionHandler.ts';
import InVivoError from '@/model/InVivoError.ts';
import { AnimalApiId } from '@/model/Animal.model.ts';

interface SummaryStepProps {
  state: CreateBulkDosingState;
  dispatch: Dispatch<StepFormAction<CreateBulkDosingState>>;
}

type ExcludedDosages = {
  weight_filtered: number;
  deceased_filtered: number;
};

export const Summary: React.FC<SummaryStepProps> = ({ state, dispatch }) => {
  const [loading, setLoading] = useState(true);
  const {
    study,
    animals: { metadata },
  } = state;
  const [excludedDoses, setExcludedDoses] = useState<ExcludedDosages>({ weight_filtered: 0, deceased_filtered: 0 });

  const displayValueRenderer = (
    instance: Handsontable.Core,
    td: HTMLTableCellElement,
    row: number,
    col: number,
    prop: string | number,
    value: {
      display_value: string | null | undefined;
      display_unit: string;
    },
    cellProperties: Handsontable.CellProperties
  ) => {
    Handsontable.renderers.TextRenderer.call(this, instance, td, row, col, prop, value, cellProperties);
    if (value.display_value === null || value.display_value === undefined) {
      td.innerHTML = '-';
    } else {
      td.innerHTML = `${formatNumber(value.display_value, true, 3)} ${value.display_unit}`;
    }
  };

  const updatedColumns = {
    colHeaders: [
      'Cage name',
      'Animal name',
      'Group name',
      'Treatment name',
      'Dose',
      'Dose volume',
      'Stock',
      'Volume',
      'Time',
      'Comment',
    ],
    columns: [
      { data: 'cage_name', type: 'text', value: 'cage_name', readOnly: true },
      { data: 'animal_name', type: 'text', value: 'animal_name', readOnly: true },
      { data: 'group_name', type: 'text', value: 'group_name', readOnly: true },
      { data: 'treatment_name', type: 'text', value: 'treatment_name', readOnly: true },
      { data: 'dose', type: 'text', value: 'dose', readOnly: true, renderer: displayValueRenderer, width: '100px' },
      { data: 'dose_volume', type: 'text', value: 'dose_volume', readOnly: true, renderer: displayValueRenderer },
      { data: 'stock', type: 'text', value: 'stock', readOnly: true, renderer: displayValueRenderer, width: '100px' },
      { data: 'volume', type: 'text', value: 'volume', readOnly: true, renderer: displayValueRenderer, width: '100px' },
      { data: 'dosed_at', type: 'datetime', value: 'Time', width: '200px' },
      { data: 'comment', type: 'text', value: 'Comment' },
    ],
    columnSorting: false,
    className: 'htMiddle',
    minSpareRows: 1,
    manualColumnResize: true,
    rowHeights: 50,
    height: 500,
    autoColumnSize: true,
    stretchH: 'all' as 'none' | 'all' | 'last' | undefined,
  };

  const ref = useRef<HotTable>();

  useEffect(() => {
    const animalIds: Array<AnimalApiId> = state.selectedAnimals?.reduce((acc: Array<AnimalApiId>, animal) => {
      if (_notNil(animal?.api_id)) {
        acc.push(animal.api_id);
      }
      return acc;
    }, []);
    const treatmentIds = state.selectedTreatments.map(({ api_id }) => api_id);
    ApiService.call({
      endpoint: 'POST /api/v1/studies/{studyId}/dosages/calculate',
      path: { studyId: study!.api_id },
      body: { animals: animalIds, treatments: treatmentIds },
    })
      .then((result) => {
        const dosages = result.body?.dosages.map((dosage: any) => {
          dosage.dosed_at = new Date().toISOString();
          return dosage;
        });
        setExcludedDoses({
          deceased_filtered: result.body?.deceased_filtered_out_count ?? 0,
          weight_filtered: result.body?.weight_filtered_out_count ?? 0,
        }); // Refactor later
        dispatch({
          type: 'updateArray',
          id: 'dosages',
          data: dosages,
        });
        setLoading(false);
      })
      .catch((error) => {
        errorToast('Failed to calculate dosages');
        ExceptionHandler.captureException(
          new InVivoError('Could not calculate dosages', {
            cause: error,
            slug: 'calculate-bulk-dosages',
          })
        );
      });
  }, []);

  const updateData = (changes: CellChange[] | null) => {
    if (_notNil(changes)) {
      const updatedSelectedDosages = [...state.dosages];

      changes.forEach((change) => {
        const [index, field, _previous, current] = change;
        updatedSelectedDosages[index] = { ...updatedSelectedDosages[index], [field]: current };
      });

      dispatch({
        type: 'updateArray',
        id: 'dosages',
        data: updatedSelectedDosages,
      });
    }
  };

  if (loading) {
    return (
      <div className="pt4">
        <Loading />
      </div>
    );
  }

  return (
    <div className="pt4" data-testid="calculate-dosages-container">
      {!(excludedDoses.weight_filtered === 0 && excludedDoses.deceased_filtered === 0) && (
        <Banner info className="mw6 mr4 mb4">
          <h3 className="f6 lh-copy fw5 pb3">One or more dosages have been excluded for the following reasons:</h3>
          <ul>
            {excludedDoses.weight_filtered !== 0 && (
              <li className="o-list ml3 f6 lh-copy near-black">
                {excludedDoses.weight_filtered} dosage(s) excluded because animals did not have recorded measurements
              </li>
            )}
            {excludedDoses.deceased_filtered !== 0 && (
              <li className="o-list ml3 f6 lh-copy near-black">
                {excludedDoses.deceased_filtered} dosage(s) excluded because animals were marked as deceased
              </li>
            )}
          </ul>
        </Banner>
      )}
      {_isEmpty(state.dosages) && <NoDataMessage title="No doses have been recorded" />}
      <div className="ow-spreadsheet-styles">
        {_isNotEmpty(state.dosages) && (
          <SpreadSheet
            className={'mt3'}
            data={state.dosages}
            settings={updatedColumns}
            metadata={metadata}
            addCol={false}
            innerRef={ref}
            maxRows={state.dosages.length}
            setInvalid={() => {}}
            afterChange={updateData}
          />
        )}
      </div>
    </div>
  );
};
