// @ts-nocheck: converted from JS

import Loading from '@/components/Loading';
import CageCardTemplateWrapper from '@/components/Modals/PrintCageCards/CageCardTemplateWrapper';
import Banner from '@/components/UI/Banner';
import Button from '@/components/UI/Button';
import APITable from '@/components/UI/Table/Reusable/APITable';
import { updateColumns, updateSettings } from '@/components/UI/Table/Reusable/Cache.utils';
import { successToast } from '@/helpers';
import { _isNotEmpty } from '@/littledash';
import Http from '@/support/http';
import { api as apiRoute, web as webRoute } from '@/support/route';
import { useEffect, useMemo, useReducer } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { colonyCagesBulkActions, generateColumns, generateFilterOptions, getCellActions, reducer } from './Cages.utils';

const Cages = ({ addToStudy = false, settings = {} }) => {
  const storeDispatch = useDispatch();
  const initialState = useMemo(
    () => ({
      updating: false,
      apiErrors: false,
      data: [],
      columns: [],
      searchQuery: '',
      filterOptions: generateFilterOptions({ fields: [] }),
      filters: [],
      filterMatch: true,
      selectedRows: {},
      total_pages: 1,
      current_page: 1,
      per_page: 10,
      order: 'desc',
      sort: ['catalog'],
      cageIdToPrint: null,
      actions: [],
    }),
    []
  );

  const [state, dispatch] = useReducer(reducer, initialState);
  const reduxTableName = addToStudy ? 'cagesAddToStudy' : 'colonyCages';
  const { columns, data, updating, apiErrors, filterOptions, cageIdToPrint, actions } = state;
  const { studyId } = useParams();
  const history = useHistory();

  const updatedSettings = useMemo(() => {
    if (_isNotEmpty(columns)) {
      // Ensure any derived columns (e.g: from measurements) are listed in the settings.
      return updateSettings(reduxTableName, columns, settings);
    }
    return settings;
  }, [columns]);

  useEffect(() => {
    if (_isNotEmpty(columns)) {
      const updatedColumns = updateColumns(reduxTableName, columns, settings);
      dispatch({
        type: 'SET_COLUMNS',
        data: updatedColumns,
      });
    }
  }, [settings?.tables]);

  const putCage = async (id, selectedFlatRows) => {
    dispatch({ type: 'SET_UPDATING', data: true });
    dispatch({ type: 'SET_ERROR', data: false });
    try {
      let cages;
      if (addToStudy) {
        cages = selectedFlatRows.map(({ original: { id, name } }) => ({
          id,
          name,
          study_id: Number(studyId),
        }));
      } else {
        cages = [{ id, study_id: null }];
      }
      await Http.put(apiRoute('cages.updateMany'), {
        cages,
      });
      if (addToStudy) {
        history.push(webRoute('studies.cages', { id: studyId }));
        successToast(`Successfully added ${cages.length > 1 ? 'cages' : 'cage'} to study`);
      }
    } catch (data) {
      dispatch({ type: 'SET_ERROR', data: true });
    } finally {
      dispatch({ type: 'SET_UPDATING', data: false });
    }
  };

  const handleRemoveCageFromStudy = async (id, name) => {
    await putCage(id);
    storeDispatch({ type: 'CLOSE_MODAL' });
    toast.success(`Successfully removed ${name} from Study`);
  };

  const getActions = (data) => {
    const actions = getCellActions(data, history, dispatch, storeDispatch, handleRemoveCageFromStudy);
    dispatch({
      type: 'SET_ACTIONS',
      data: actions,
    });
  };

  useEffect(() => {
    fetchFilterOptions();
  }, [actions]);

  const fetchFilterOptions = async () => {
    try {
      const { data } = await Http.get(apiRoute('cages.filterOptions'));
      dispatch({
        type: 'SET_FILTER_OPTIONS',
        data: generateFilterOptions(data, addToStudy),
      });
      dispatch({
        type: 'SET_COLUMNS',
        data: generateColumns(data, settings?.tables?.[reduxTableName]?.columns, actions, addToStudy),
      });
    } catch (data) {
      dispatch({ type: 'SET_ERROR', data: true });
    }
  };

  useEffect(() => {
    if (_isNotEmpty(data)) {
      getActions(data);
    }
  }, [data]);

  const handleDelete = async (selectedTableRows, callback) => {
    try {
      await Http.delete(apiRoute('cages.destroyMany', {}), {
        data: {
          cage_ids: selectedTableRows.map(({ id }) => id),
        },
      });
      return callback('Successfully deleted!');
    } catch (error) {
      dispatch({ type: 'SET_ERROR', data: true });
    }
  };

  const renderAddToStudyActions = ({ selectedFlatRows }) => {
    if (addToStudy) {
      const disabled = !selectedFlatRows.length;
      return (
        <div className="flex pa1 mt4 mb2 justify-start">
          <Button
            disabled={disabled}
            tooltip={disabled ? 'Please select at least one cage to add to the study' : undefined}
            onClick={() => putCage(0, selectedFlatRows)}
          >
            Assign
          </Button>
          <Button className="ml2" plain url={webRoute('studies.cages', { id: studyId })}>
            Cancel
          </Button>
        </div>
      );
    }
  };

  const bulkActions = (tableProps) =>
    !addToStudy && colonyCagesBulkActions(tableProps, handleDelete, storeDispatch, history);

  // OR-2531: When adding cages to a study with colony, filter out cages already in one.
  const filterOutStudy = {
    type: 'and',
    op: 'eq',
    filter: 'study',
    value: null,
  };

  const loadingTxt = 'Fetching cages...';
  return (
    <>
      <div className="ma4">
        {apiErrors && (
          <Banner critical dismiss={false} className="mw6 ma3">
            <h3 className="f5 normal lh-title pb2">There was an error fetching cages</h3>
            <p className="f6 pb3 lh-copy">If this error persists try again later or contact support.</p>
            <Button outline critical url={'mailto:support@benchling.com'}>
              Contact support
            </Button>
          </Banner>
        )}
        <div className={`cages ${updating ? 'ui__disabled' : ''}`}>
          {updating ? (
            <div style={{ height: 530 }}>
              <Loading txt={loadingTxt} />
            </div>
          ) : (
            <APITable
              apiInfo={{ type: 'legacyInternalApi', route: apiRoute('cages.index') }}
              customFilter={addToStudy ? filterOutStudy : null}
              includeParam="counts,meta,study_link,operations"
              defaultSortBy={{ id: 'id', desc: true }}
              columns={columns.filter((col) => col.Header || col.id === 'actions')}
              bulkActions={bulkActions}
              filterOptions={filterOptions}
              searchPlaceholderText="Search by Cage No or Name"
              pageSizes={[50, 100, 200, 300]}
              renderCustomActions={renderAddToStudyActions}
              settings={updatedSettings}
              reduxTableName={reduxTableName}
              loadingText={loadingTxt}
            />
          )}
        </div>
      </div>
      {cageIdToPrint && (
        <CageCardTemplateWrapper
          cageId={cageIdToPrint}
          onComplete={() => dispatch({ type: 'SET_CAGE_ID_TO_PRINT', data: null })}
        />
      )}
    </>
  );
};

export default Cages;
